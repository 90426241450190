import React, { memo } from 'react'
import './card.less'

const Card = memo(props => {
  const { name, description, link, image, className } = props

  return (
    <div className="portfolioCard">
      <div className="cardWrapper">
        <div className={`cardContent ${className ? className : ''}`}>
          <img src={image} alt="name" className="cardImage" />
          <div className="descriptionCard">
            <div className="descriptionCardText">{description}</div>
          </div>
        </div>
      </div>
      <a href={link} target="_blank" className="cardLink">
        <div className="cardName">{name}</div>
      </a>
    </div>
  )
})

export default Card
