import React from 'react'
import './project.less'

const ProjectItem = props => {
  const { name, description, link } = props

  return (
    <a href={link} className="projectItem" target="_blank">
      <div className="name">{name}</div>
      <div className="description">{description}</div>
    </a>
  )
}

export default ProjectItem
