import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function HEAD({
  description,
  lang,
  meta,
  keywords,
  title,
  locales,
  ogDescription,
  image
}) {
  const { site } = useStaticQuery(
    graphql`
      query SEO {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteURL
            defaultLocale
          }
        }
      }
    `
  )
  const metaTitle = title || site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description
  const metaOgDescription = ogDescription || metaDescription
  const metaOgImage = image || site.siteMetadata.image

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaOgDescription
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:title`,
          content: title
        },
        {
          name: `og:image`,
          content: metaOgImage
        }
      ]

        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
    />
  )
}

HEAD.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
}

HEAD.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  locales: PropTypes.array
}

export default HEAD
