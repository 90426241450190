const content = [
  {
    name: 'Sidus heroes',
    icon: '/icons/images/sidusHeroes.webp',
    link: 'https://sidusheroes.com',
    description:
      'SIDUS HEROES is an exciting NFT metaverse that’s accessible in one click through your web browser. It invites players into a futuristic Sci-Fi world that offers unique Play-to-Earn mechanics, high-quality graphics and thrilling gameplay.'
  },
  {
    name: 'nft stars',
    icon: '/icons/images/nftStars.webp',
    link: 'https://nftstars.app/en/',
    description:
      'NFT Stars is a unique digital-art ecosystem and artist-oriented NFT marketplace. It utilizes innovative solutions that make the NFT market accessible to talented creators and art collectors.'
  },
  {
    name: 'nft RADIO',
    icon: '/icons/images/nftRadio.webp',
    link: 'https://nft-radio.com',
    description:
      'NFT Radio is a media platform that focuses on different types of audio content. It features a variety of artists, DJs and musicians, providing them the opportunity to showcase their talent and hold discussions on topics they are interested in.'
  },
  {
    name: 'marnotaur',
    icon: '/icons/images/marnotaur.webp',
    link: 'https://marnotaur.com/en',
    description:
      'Marnotaur is the first-ever decentralized liquidity protocol for secure undercollateralized margin trading. Users can multiply their gains thanks to the protocol’s compatibility with all DeFi solutions and networks.'
  },
  {
    name: 'XXXX',
    icon: '',
    link: 'platinum.fund',
    description:
      'Blockchain Tech. Incubator for Crypto and IT startups. Platinum is also well-known VC fund.'
  },
  {
    name: 'ar gallery',
    icon: '',
    link: 'https://nftstars.app/en/argallery/',
    description:
      'AR Gallery is an innovative solution for NFT creators who are striving to break down the barriers between the digital world and the real world. It offers fully customized Augmented Reality exhibitions that display its artists’ best works.'
  },
  {
    name: 'poolfarming',
    icon: '',
    link: 'https://www.figma.com/file/LT0EXCr79AIaXsNJWULMpJ/New-PoolFarming',
    description:
      'Whitelabel solution for DeFi Primitives on EVM Chains: Creating Farming Pools, Set upping  Staking and AMM Engines'
  },
  {
    name: 'qdao defi',
    icon: '',
    link:
      'https://www.figma.com/file/epf2QfsXdME0gpmxOJc31u/QDAO-DeFi---CeFi---Web',
    description:
      'The bunch of services to operate with crypto assets , such as earning on deposits, taking loans etc.'
  },
  {
    name: 'QDAO Platform',
    icon: '',
    link: 'https://qdefi.io/en',
    description:
      'A platform and DAPP that supports smart investments in liquid cryptocurrencies, instant fiat loans and deposits with the highest interest rates.'
  },
  {
    name: 'Marnotaur Platform',
    icon: '',
    link:
      'https://www.figma.com/file/VHApFHfa4ppGAoJgBEhzjs/Marnotaur---Platform',
    description:
      'MARNOTAUR PLATFORM Marnotaur is a liquidity protocol for secure undercollateralized margin.'
  },
  {
    name: 'Cheesus Website',
    icon: '',
    link: 'https://cheesus.ai/about/en/',
    description:
      'Cheesus is a multichain DeFi analytical tool that helps you find real gems without getting caught in any mousetraps. Cheesus is the NFT metaverse portfolio tracking, customizable datasets, the biggest ratings source for DeFi projects, with the first decentralized Copy Trading feature and multichain aggregated data.'
  },
  {
    name: 'Cheesus ProfitScanner',
    icon: '',
    link: 'https://cheesus.ai',
    description:
      'Cheesus profitscanner - Cheesus Tool that: Check trading history any wallet,Find real holders for any token, Discover Flippers and Diamond hands for any DeFi project.'
  },
  {
    name: 'SpaceSwap',
    icon: '',
    link: 'https://spaceswap.app',
    description:
      'SPACESWAP SpaceSwap is a unique aggregator that aims to unite the most popular DeFi protocols in a one-stop station. Safe, fast, cheap.'
  },
  {
    name: 'Lock’n’ trade Platform',
    icon: '',
    link: 'https://www.figma.com/file/dnI0TerteO23VrdKNUZyWN/Platinum-Locker',
    description: `LOCK'N'TRADE - Create You own custom token lock instantly. All coins are locked into our audited smart contract and can only be withdrawn by you after lock time expires`
  },
  {
    name: 'Degen Score Platform',
    icon: '',
    link:
      'https://www.figma.com/file/o0ajpAzXjRrkNDRaBYTHUm/RESEARCH-Binance-Smart-Chain-BSC',
    description:
      'Degen Score Platform - Funny tool to Score the Metamask wallet address and provide a free NFT of your degen score rank'
  }
]

export default content
