import React, { useState } from 'react'
import './section_portfolio.less'
import Card from '../../card'
import content from './content/content'
import ProjectItem from '../../projectItem'
import OutlineButton from '../../button/button'

const Portfolio = () => {
  const [startAnim, setStartAnim] = useState(false)
  const projectsWithAnImage = content.slice(0, 4)
  const projectsWithoutAnImage = content.slice(5)
  const [open, setOpen] = React.useState(false)

  return (
    <section className="section section_portfolio">
      <div className="wrapper_section-content">
        <div className="section__cards">
          {projectsWithAnImage &&
            projectsWithAnImage.map((item, i) => (
              <Card
                // className={i === 0 || i === 3 ? 'smallCard' : 'bigCard'}
                link={item.link}
                description={item.description}
                image={item.icon}
                name={item.name}
                key={'portfolio' + i}
              />
            ))}
        </div>
        {open ? (
          <div className="allProjects">
            {projectsWithoutAnImage.map((item, i) => {
              return (
                <ProjectItem
                  link={item.link}
                  key={`project_${i}`}
                  name={item.name}
                  description={item.description}
                />
              )
            })}
          </div>
        ) : null}
        <div onClick={() => setOpen(!open)} className="allProjectsBtn">
          <div id="portfolioButton" className="buttonText">
            {open ? 'hide project’s' : 'all project’s'}
          </div>
          <OutlineButton id="portfolioButton" setStartAnim={setStartAnim}>
            <svg
              className="buttonBorder"
              viewBox="0 0 492 194"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={startAnim ? 'outline' : ''}
                d="M491.5 97C491.5 110.208 484.713 122.844 472.327 134.391C459.94 145.939 441.989 156.361 419.765 165.124C375.319 182.649 313.884 193.5 246 193.5C178.116 193.5 116.681 182.649 72.2351 165.124C50.0109 156.361 32.0597 145.939 19.6728 134.391C7.28677 122.844 0.5 110.208 0.5 97C0.5 83.7916 7.28677 71.1559 19.6728 59.6089C32.0597 48.0613 50.0109 37.639 72.2351 28.8758C116.681 11.3505 178.116 0.5 246 0.5C313.884 0.5 375.319 11.3505 419.765 28.8758C441.989 37.639 459.94 48.0613 472.327 59.6089C484.713 71.1559 491.5 83.7916 491.5 97Z"
                stroke="white"
              />
            </svg>
          </OutlineButton>
        </div>
      </div>
    </section>
  )
}

export default Portfolio
